import { Session, User } from '@supabase/supabase-js';
import { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../utils/supabase';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({
  user: null,
} as any);

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User | null>(null);
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<any>();

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      if (_event !== 'SIGNED_OUT') {
        setSession(session);
        setUser(session?.user || null);
        console.log('session', session?.user);
        if (window.location.pathname === '/login') {
          navigate('/');
        }
      }
      setLoading(false);
    });
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const { data, error } = await supabase.from('organizations').select('*').single();

      if (!error) {
        setOrganization(data);
      }
    };

    fetchOrganizations();
  }, []);

  // In case we want to manually trigger a signIn (instead of using Auth UI)
  const signIn = async () => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: { skipBrowserRedirect: false },
    });
    return { data, error };
  };

  const signOut = async () => {
    const { error } = await supabase.auth.signOut();
    if (!error) {
      setUser(null);
      setSession(null);
    }
    return { error };
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, session, organization }}>
      {!loading ? children : ``}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
