import reactLazyWithRetry from '@fatso83/retry-dynamic-import/react-lazy';

export const mainRoutes = [
  { path: '/', component: reactLazyWithRetry(() => import('@/pages/audits/AuditLogs')) },
  { path: '/analytics', component: reactLazyWithRetry(() => import('@/pages/Analytics')) },
  { path: '/settings', component: reactLazyWithRetry(() => import('@/pages/GeneralSettings')) },
  {
    path: '/providers',
    component: reactLazyWithRetry(() => import('@/pages/providers/ManageProviders')),
  },
  {
    path: '/patients',
    component: reactLazyWithRetry(() => import('@/pages/patients/ManagePatients')),
  },
];

export const auditRoutes = [
  { path: '/audits/new', component: reactLazyWithRetry(() => import('@/pages/audits/NewAudit')) },
  {
    path: '/audits/new-autopilot',
    component: reactLazyWithRetry(() => import('@/pages/audits/NewAutopilotAudit')),
  },
  { path: '/audits/:id', component: reactLazyWithRetry(() => import('@/pages/audits/AuditView')) },
  {
    path: '/audits/:id/provider/:provider_id',
    component: reactLazyWithRetry(() => import('@/pages/audits/AuditResults')),
  },
  {
    path: '/audits/:id/patient/:patient_id',
    component: reactLazyWithRetry(() => import('@/pages/audits/AuditResults')),
  },
  {
    path: '/audits/:id/patient/:patient_id/asset/:asset_id',
    component: reactLazyWithRetry(() => import('@/pages/audits/SendRemediation')),
  },
  {
    path: '/audits/:id/provider/:provider_id/asset/:asset_id',
    component: reactLazyWithRetry(() => import('@/pages/audits/SendRemediation')),
  },
  {
    path: '/audits/settings',
    component: reactLazyWithRetry(() => import('@/pages/audits/Settings/AuditSettings')),
  },
  { path: '/audits/data', component: reactLazyWithRetry(() => import('@/pages/audits/AuditData')) },
  {
    path: '/audits/data/:asset_id',
    component: reactLazyWithRetry(() => import('@/pages/audits/AuditDataView')),
  },
];

export const assetRoutes = [
  { path: '/assets', component: reactLazyWithRetry(() => import('@/pages/assets/Assets')) },
  {
    path: '/assets/:id/details',
    component: reactLazyWithRetry(() => import('@/pages/assets/Details')),
  },
  { path: '/assets/chat', component: reactLazyWithRetry(() => import('@/pages/assets/Chat')) },
];
