import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '../utils/supabase';

const Login = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-default-background">
      <div className="flex w-full max-w-[320px] flex-col items-center justify-center gap-4 border border-solid border-neutral-border pt-12 pr-12 pb-12 pl-12">
        <div className="flex flex-col items-center justify-center gap-1">
          <span className="text-heading-2 font-heading-2 text-default-font">Welcome</span>
          <span className="text-body font-body text-subtext-color">Login or sign up below</span>
        </div>
        <div className="flex w-full flex-col">
          <Auth
            supabaseClient={supabase}
            providers={['google', 'azure']}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: 'blue',
                    brandAccent: 'blue',
                  },
                },
              },
            }}
            showLinks={true}
          />
        </div>
        <span className="text-caption-bold font-caption-bold text-subtext-color text-left w-full">
          Not working? Contact Support
        </span>
      </div>
    </div>
  );
};

export default Login;
