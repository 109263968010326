import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import { mainRoutes, auditRoutes, assetRoutes } from './routes';
import LazyRoute from './components/LazyRoute';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        {mainRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<LazyRoute component={route.component} />}
          />
        ))}
        {auditRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<LazyRoute component={route.component} />}
          />
        ))}
        {assetRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<LazyRoute component={route.component} />}
          />
        ))}
      </Route>

      <Route path="/login" element={<Login />} />
    </Routes>
  );
}
